<template>
    <v-container fluid>
      <v-row class="align-center pe-2 pt-5">
        <v-col cols="6" class="d-flex align-center">
          <v-icon class="me-2">mdi-account-multiple</v-icon>
          <span class="text-h6">{{ $t('feedbacks') }}</span>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            density="compact"
            :label="$t('search')"
            single-line
            flat
            hide-details
            variant="solo-filled"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="ma-2"></v-divider>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" v-for="feedback in feedbacks" :key="feedback.id" class="pa-2">
          <v-card
            class="d-flex flex-column ma-2"
            outlined
            height="170"
            @click="redirectToFeedback(feedback.id)"
          >
            <v-card-title class="text-h6 d-flex align-center">
              <v-icon start class="me-2">mdi-comment-text-outline</v-icon>
              {{ feedback.name }}
            </v-card-title>
            <v-card-subtitle class="d-flex flex-wrap text-body-2">
              <span class="me-3 d-flex align-center">
                <v-icon start size="16" class="me-1">mdi-email-outline</v-icon>
                {{ feedback.email }}
              </span>
              <span class="d-flex align-center">
                <v-icon start size="16" class="me-1">mdi-calendar-clock</v-icon>
                {{ formatDate(feedback.createdAt) }}
              </span>
            </v-card-subtitle>
            <v-card-text class="text-truncate">
              {{ feedback.message }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { onUnmounted } from 'vue';
import { useFeedbackStore } from '../../stores/feedbackStore';
import { useRouter } from 'vue-router';

const { mobile } = useDisplay();

const { t } = useI18n();

const feedbackStore = useFeedbackStore();

const router = useRouter();

const selected = ref([]);
const itemsPerPage = ref(20);
const search = ref("");
const sortBy = ref([{key: 'createdAt', order: 'desc'}]);
const loading = ref(false);
const feedbacks = computed(() => feedbackStore.feedbacks);

const headers = computed(() => {
        if (mobile.value) {
            return [
                {title: t('date'), align: 'start', sortable: true, key: 'createdAt'},
            ]
        }
        return [
            {title: t('date'), align: 'start', sortable: true, key: 'createdAt'},
            {title: t('user'), align: 'start', sortable: true, key: 'createdBy.fullName'},
        ];

});


function select(event, {item})
{
    //userDialog.value.openUser(item);
    //this.$refs.voucherDialog.openVoucher(item);
}

const redirectToFeedback = (id: number) => {
    router.push({name: 'Feedback', params: {id: id}})
};

const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit"
    });
};


feedbackStore.getFeedbacks();

const timer = setInterval(() => feedbackStore.getFeedbacks(), 10000);

onUnmounted(() => {
    clearInterval(timer);
})


</script>
