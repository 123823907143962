import axios from "axios";
import { defineStore } from "pinia";
import { Ref, ref } from 'vue';
import { UserFeedback } from '../models/UserFeedback';
import { Feedback } from "@sentry/vue";

export const useFeedbackStore = defineStore('FeedbackStore', () => {
    let feedbacks: Ref<Array<UserFeedback>> = ref([]);

    function getFeedbacks(): Promise<Array<UserFeedback>>
    {
        return new Promise<Array<UserFeedback>>((resolve, reject) => {
            let url = '/api/user-feedbacks';

            axios.get(url).then(result => {
                const fetchedResult = UserFeedback.arrayFromJson(result.data);
                feedbacks.value = fetchedResult;

                resolve(feedbacks.value);
            }).catch(error => {
                console.error(error);
                reject(error);
            })

        })
    }

    async function getFeedbackById(id: Number|String): Promise<UserFeedback|null>
    {
        return new Promise<UserFeedback|null>((resolve, reject) => {
            let feedback = feedbacks.value.find((feedback) => feedback.id == id);

            if (feedback) {
                resolve(feedback);
            } else {
                let p = new UserFeedback(false);
                p.id = Number(id);
                let url = UserFeedback.getRoute(p);
                axios.get(url).then((result) => {
                    let feedback = UserFeedback.fromJson(result.data);
                    resolve(feedback);
                }).catch(error => {
                    reject(error);
                })
            }
        });
    }


    return {
        feedbacks,
        getFeedbacks,
        getFeedbackById,
    }
})
