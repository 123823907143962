<template>

    <page-header
        v-if="feedback"
        :title="'Feedback from ' + feedback.name"
        icon="mdi-account"
        :routeBack="{name: 'Feedbacks'}"
    />
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="8">
          <h1 class="text-h4 mb-4">Feedback Details</h1>

          <template v-if="feedback">
            <v-row class="mb-2">
              <v-col cols="12" md="6">
                <p class="text-subtitle-1">
                  <v-icon size="16" class="me-1">mdi-account</v-icon>
                  <strong>Name:</strong> {{ feedback.name }}
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <p class="text-subtitle-1">
                  <v-icon size="16" class="me-1">mdi-email-outline</v-icon>
                  <strong>Email:</strong> {{ feedback.email }}
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <p class="text-subtitle-1">
                  <v-icon size="16" class="me-1">mdi-calendar-clock</v-icon>
                  <strong>Date:</strong> {{ formatDate(feedback.createdAt) }}
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <p class="text-subtitle-1">
                  <v-icon size="16" class="me-1">mdi-desktop-classic</v-icon>
                  <strong>System:</strong> {{ feedback.system }}
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <p class="text-subtitle-1">
                  <img :src="getFlagUrl(feedback.locale)" alt="" class="flag-icon me-1" />
                  <strong>Locale:</strong> {{ getLocaleName(feedback.locale) }}
                </p>
              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <div>
              <h2 class="text-h5">Message</h2>
              <p class="text-body-1">{{ feedback.message }}</p>
            </div>

            <v-divider class="my-4"></v-divider>

            <v-row justify="end">
              <v-btn :loading="isSaving" color="success" class="me-2" @click="archiveFeedback">
                Archive
              </v-btn>
              <v-btn color="error" @click="confirmDelete">
                Delete
              </v-btn>
            </v-row>
          </template>

          <v-alert v-else type="info" class="mt-4">
            Loading feedback details...
          </v-alert>
        </v-col>
      </v-row>

      <!-- Bekreftelsesdialog for sletting -->
      <v-dialog v-model="confirmDeleteDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="text-h5">Confirm Deletion</v-card-title>
          <v-card-text>
            Are you sure you want to delete this feedback? This action cannot be undone.
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn text @click="confirmDeleteDialog = false">Cancel</v-btn>
            <v-btn color="error" @click="deleteFeedback">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>

  <script setup lang="ts">
  import { ref, computed, onMounted } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useFeedbackStore } from "../../stores/feedbackStore";  // Oppdatert path
  import { UserFeedback } from "../../models/UserFeedback";       // Oppdatert path
  import { DateTime } from "luxon";
  import PageHeader from '/resources/js/components/PageHeader.vue';

  const route = useRoute();
  const router = useRouter();
  const feedbackStore = useFeedbackStore();

  const feedback = ref<UserFeedback | null>(null);
  const confirmDeleteDialog = ref(false);

  const components = [
    PageHeader
  ]

  const isSaving = computed(() => feedback.value?.isSaving ?? false);

  // Laster feedback basert på id i route params
  const loadFeedback = async () => {
    const feedbackId = route.params.id as string;
    try {
      feedback.value = await feedbackStore.getFeedbackById(feedbackId);
    } catch (error) {
      console.error("Failed to load feedback:", error);
      router.push({ name: "Feedbacks" });  // Tilbake til listen ved feil
    }
  };

  const formatDate = (date: DateTime | null): string => {
    return date ? date.toFormat("dd MMM yyyy, HH:mm") : "N/A";
  };

  const getFlagUrl = (locale: string): string => {
    const countryCode = locale.split("-")[1]?.toLowerCase() || "us";
    return `https://flagcdn.com/w40/${countryCode}.png`;
  };

  const getLocaleName = (locale: string): string => {
    const locales: Record<string, string> = {
      "en-US": "United States (English)",
      "nb-NO": "Norway (Norwegian)",
      "fr-FR": "France (French)"
    };
    return locales[locale] || locale;
  };

  const confirmDelete = () => {
    confirmDeleteDialog.value = true;
  };

  const deleteFeedback = async () => {
    if (!feedback.value) return;
    try {
      await feedback.value.delete();
      router.push({ name: "Feedbacks" });
    } catch (error) {
      console.error("Failed to delete feedback:", error);
    }
  };

  const archiveFeedback = async () => {
    if (!feedback.value) return;
    try {
      feedback.value.resolvedAt = DateTime.now();
      await feedback.value.save();
      console.log("Feedback archived.");
    } catch (error) {
      console.error("Failed to archive feedback:", error);
    }
  };

  onMounted(loadFeedback);
  </script>

  <style scoped>
  .flag-icon {
    width: 20px;
    height: 15px;
    vertical-align: middle;
  }
  </style>
